@import 'styles/includes';

.CardCta {
    $root: &;
    @extend %container;

    &__Container {
        display: flex;
        flex-direction: column;
        border-radius: 0.8rem;
        overflow: hidden;

        @include media(ml) {
            flex-direction: row;
        }
    }

    &__ImageContainer {
        position: relative;

        @include media(ml) {
            width: 48%;
            flex-shrink: 0;
        }

        &::after {
            display: block;
            content: '';
            height: 0;
            padding-bottom: calc(100% / (2 / 1));

            @include media(ml) {
                padding-bottom: calc(100% / (545 / 314));
            }
        }
    }

    &__Image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__Content {
        color: #fff;
        background-color: $colorBlue;
        padding: 2.6rem;

        @include media(ml) {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        #{$root}--Gradient & {
            color: $colorBlue;
            background: $colorGradient;
        }
    }

    &__Title {
        @extend %h4;
        margin-bottom: 0.8rem;
    }

    &__Text {
        line-height: 1.625;
    }

    &__Button {
        margin-top: 2.4rem;
    }
}
